import React, { createContext, useContext, useState, ReactNode } from "react";
import {
  LoginDocument,
  LoginMutation,
  LoginMutationVariables,
  LogoutDocument,
  LogoutMutation,
  UserType,
  WhoAmIDocument,
  WhoAmIQuery,
} from "./type";

import { useGqlContext } from "./GqlContext";
import { User } from "lucide-react";
import { useNavigate } from "react-router";

type User = NonNullable<LoginMutation["login"]["account"]>["user"];

type Customer = Extract<User, { __typename?: "Customer" }>;
type StaffMember = Extract<User, { __typename?: "StaffMember" }>;

export type AuthData = {
  accountId: string | null;
  email: string | null;
  userType: UserType | null;
  isAuthenticated: boolean;
};

interface AuthContext {
  authData: AuthData;
  login: (email: string, password: string) => Promise<AuthData>;
  logout: () => void;
  validateAuthentication: () => Promise<void>;
  staffMember: StaffMember | undefined;
  customer: Customer | undefined;
}

const AuthContext = createContext<AuthContext | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { queryClient, graphQLClient } = useGqlContext();

  const LOCAL_STORAGE_AUTH = ".auth";
  const UNAUTHENTICATED: AuthData = {
    accountId: null,
    email: null,
    userType: null,
    isAuthenticated: false,
  };

  const [authData, setAuthData] = useState<AuthData>(UNAUTHENTICATED);
  const [customer, setCustomer] = useState<Customer | undefined>(undefined);
  const [staffMember, setStaffMember] = useState<StaffMember | undefined>(
    undefined
  );

  const setUser = (user: Customer | StaffMember | undefined) => {
    if (user === undefined) {
      setCustomer(undefined);
      setStaffMember(undefined);
      return;
    }

    if (user.type === UserType.StaffMember) {
      const staffMember = user as StaffMember;
      setStaffMember({ ...staffMember });
      setCustomer(undefined);
      return;
    } else if (user.type === UserType.Customer) {
      const customer = user as Customer;
      setCustomer({ ...customer });
      setStaffMember(undefined);
      return;
    }

    // BETTER HANDLING
    throw new Error("Unexpected authentication error.");
  };

  const login = async (email: string, password: string): Promise<AuthData> => {
    try {
      const payload = (
        await queryClient.fetchQuery({
          queryKey: ["login"],
          queryFn: async () =>
            graphQLClient.request<LoginMutation, LoginMutationVariables>({
              document: LoginDocument,
              variables: { email: email, password: password },
            }),
          staleTime: 0,
        })
      ).login;
      let authData: AuthData;
      if (payload.errors.length > 0) {
        authData = UNAUTHENTICATED;
      } else if (payload.account) {
        authData = {
          accountId: payload.account.id,
          email: payload.account.email,
          userType: payload.account.userType,
          isAuthenticated: true,
        };
        localStorage.setItem(LOCAL_STORAGE_AUTH, JSON.stringify(authData));
        setUser({ ...payload.account.user });
      } else {
        console.error(payload);
        throw new Error("Unexpected authentication error.");
      }
      setAuthData(authData);
      return authData;
    } catch (error) {
      console.error("Login failed", error);
      return UNAUTHENTICATED;
    }
  };

  const logout = () => {
    queryClient
      .fetchQuery({
        queryKey: ["logout"],
        queryFn: async () =>
          graphQLClient.request<LogoutMutation>({
            document: LogoutDocument,
          }),
      })
      .then((result) => {
        setUser(undefined);
        setAuthData((prev) => ({
          ...prev,
          ...UNAUTHENTICATED,
        }));
        if (result.logout) {
          localStorage.removeItem(LOCAL_STORAGE_AUTH);
        }
      });
  };

  const validateAuthentication = async () => {
    const unauth = () => {
      setUser(undefined);
      localStorage.removeItem(LOCAL_STORAGE_AUTH);
      setAuthData(UNAUTHENTICATED);
    };

    let authDataRaw = localStorage.getItem(LOCAL_STORAGE_AUTH);
    if (!authDataRaw) {
      unauth();
      return;
    }

    let authData: AuthData;
    try {
      authData = JSON.parse(authDataRaw);
    } catch {
      unauth();
      return;
    }
    if (authData.isAuthenticated) {
      try {
        const { whoAmI: account } = await queryClient.fetchQuery({
          queryKey: ["whoAmI"],
          queryFn: async () =>
            graphQLClient.request<WhoAmIQuery>({
              document: WhoAmIDocument,
            }),
          staleTime: 0,
        });
        setUser({ ...account.user });
        authData = {
          accountId: account.id,
          email: account.email,
          userType: account.userType,
          isAuthenticated: true,
        };
        setAuthData({ ...authData });
        return;
      } catch (ex: any) {
        if (ex && ex.response) {
          const response = ex.response;
          if (response.status && response.status === 200) {
            if (response.errors && response.errors.length === 1) {
              const error = response.errors[0];
              if (
                error.extensions &&
                error.extensions.code === "AUTH_NOT_AUTHORIZED"
              ) {
                unauth();
                return;
              }
            }
          }
        }
      }
    }
    unauth();
  };

  return (
    <AuthContext.Provider
      value={{
        authData,
        login,
        logout,
        validateAuthentication,
        staffMember,
        customer,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContext => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return context;
};
